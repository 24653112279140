<template>
  <main id="content" class="event">
    <router-view @input="onInput"></router-view>
  </main>
</template>

<script>

export default {
  name: 'MobileEvent',
  data() {
    return {
      EventDetail: undefined,
    };
  },
  methods: {
    onInput(eventDetail) {
      this.EventDetail = eventDetail;
    },
  },
};
</script>
